import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import ProductsList from "../components/UI/ProductsList";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import "../styles/product-detail.css";
import { useDispatch } from "react-redux";
import { cartActions } from "../redux/slices/cartSlice";
import { toast } from "react-toastify";
import { db } from "../firebase.config";
import { doc, getDoc } from "firebase/firestore"
import useGetData from "../custom-hooks/useGetdata";

const ProductDetail = () =>
{
  
  const [product,setProduct] = useState({})
  const [tab, setTab] = useState("desc");
  const reviewUser = useRef("");
  const reviewMsg = useRef("");
  const [rating, setRating] = useState(null);
  const dispatch = useDispatch();

  const { id } = useParams();
  // const product = products.find((item) => item.id === id);
  const { data: products } = useGetData("products");
  const docRef = doc(db, "products", id)
  
  useEffect(() =>
  {
    const getProduct = async () =>
    {
      const docSnap = await getDoc(docRef)
      if (docSnap.exists())
      {
        setProduct(docSnap.data());
      } 
    }
    getProduct();
  })
  const {
    imgUrl,
    productName,
    price,
    // avgRating,
    // reviews,
    description,
    category,
  } = product;

  const relatedProducts = products.filter((item) => item.category === category);
  const submitHandler = (e) => {
    e.preventDefault();
    e.target.reset();
    const reviewUserName = reviewUser.current.value;
    const reviewUserMsg = reviewMsg.current.value;
    // console.log(reviewUserName, reviewUserMsg,rating);
    const reviewObj = {
      userName: reviewUserName,
      text: reviewUserMsg,
      rating,
    };
    console.log(reviewObj);
    toast.success("Review submited successfully.")
  };

  const addToCart = () => {
    dispatch(
      cartActions.addItem({
        id,
        imgUrl: imgUrl,
        productName,
        price,
      })
    );
    toast.success("Product added successfully.");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Helmet title={productName}>
      <CommonSection title={productName} />
      <section className="pt-0">
        <Container>
          <Row>
            <Col lg="6">
              <img src={imgUrl} alt="" className="images"/>
            </Col>
            <Col lg="6">
              <div className="product__detail">
                <h2>{productName}</h2>
                <div className="product__rating d-flex align-items-center gap-3">
                  <div>
                    <span onClick={() => setRating(1)}>
                      <i className="ri-star-s-fill"></i>
                    </span>
                    <span onClick={() => setRating(2)}>
                      <i className="ri-star-s-fill"></i>
                    </span>
                    <span onClick={() => setRating(3)}>
                      <i className="ri-star-s-fill"></i>
                    </span>
                    <span onClick={() => setRating(4)}>
                      <i className="ri-star-s-fill"></i>
                    </span>
                    <span onClick={() => setRating(5)}>
                      <i className="ri-star-half-s-line"></i>
                    </span>
                  </div>
                  {/* <p>
                    (<span>{avgRating}</span> Ratings)
                  </p> */}
                </div>
                <div className="d-flex align-items-center gap-5">
                  <span className="product__price">
                    {price} ETB
                  </span>
                  <span>Category: {category}</span>
                </div>
                <p className="mt-3 descp">{description}</p>
                <motion.button
                  whileTap={{ scale: 1.2 }}
                  className="buy__btn"
                  onClick={addToCart}
                >
                  <Link to="#">Add to Cart</Link>
                </motion.button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col lg="12">
              <div className="tab__wrapper d-flex align-items-center gap-3">
                <h6
                  className={`${tab === "desc" ? "activ__tab" : ""}`}
                  onClick={() => setTab("desc")}
                >
                  Description
                </h6>
                <h6
                  className={`${tab === "rev" ? "activ__tab" : ""}`}
                  onClick={() => setTab("rev")}
                >
                   Reviews ({0}) 
                </h6>
              </div>
              {tab === "desc" ? (
                <div className="tab__content mt-5">
                  <p className="descp">{description}</p>
                </div>
              ) : (
                <div className="product__review">
                  <div className="review__wrapper">
                    {/* <ul>
                      {reviews?.map((item, index) => (
                        <li key={index} className="mb-4">
                          <h6>anonymous</h6>
                          <span>{item.rating} (rating)</span>
                          <p className="descp">{item.text}</p>
                        </li>
                      ))}
                    </ul> */}
                    <div className="review__form">
                      <h4>leave your experience</h4>
                      <form action="" onSubmit={submitHandler}>
                        <div className="form__group">
                          <input
                            type="text"
                            placeholder="Enter Name"
                              ref={reviewUser}
                              required
                          />
                        </div>
                        <div className="form__group d-flex align-items-center gap-5 rating__group">
                          <motion.span
                            whileTap={{ scale: 1.5 }}
                            onClick={() => setRating(1)}
                          >
                            1<i className="ri-star-s-fill"></i>
                          </motion.span>
                          <motion.span
                            whileTap={{ scale: 1.5 }}
                            onClick={() => setRating(2)}
                          >
                            2<i className="ri-star-s-fill"></i>
                          </motion.span>
                          <motion.span
                            whileTap={{ scale: 1.5 }}
                            onClick={() => setRating(3)}
                          >
                            3<i className="ri-star-s-fill"></i>
                          </motion.span>
                          <motion.span
                            whileTap={{ scale: 1.5 }}
                            onClick={() => setRating(4)}
                          >
                            4<i className="ri-star-s-fill"></i>
                          </motion.span>
                          <motion.span
                            whileTap={{ scale: 1.5 }}
                            onClick={() => setRating(5)}
                          >
                            5<i className="ri-star-s-fill"></i>
                          </motion.span>
                        </div>
                        <div className="form__group">
                          <textarea
                            ref={reviewMsg}
                            rows={4}
                            type="text"
                              placeholder="Reviews Message"
                              required
                          />
                        </div>
                        <motion.button
                          whileTap={{ scale: 1.3 }}
                          className="buy__btn"
                          type="submit"
                        >
                          Submit
                        </motion.button>
                      </form>
                    </div>
                  </div>
                </div>
              )}
            </Col>
            <Col lg="12" className="mt-5">
              <h2 className="related__title">You maight also like</h2>
            </Col>
            <ProductsList data={relatedProducts} />
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default ProductDetail;
